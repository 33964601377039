import { Snackbar, Alert } from "@mui/material"
import React from "react"

type Props = {
  alert_text: string
}
export const SnackbarAlert: React.FC<Props> = ({ alert_text }) => {
  const [displayAlert, setDisplayAlert] = React.useState(true)
  //   const { openSnackbar, setOpenSnackbar } = useSnackbar()

  return (
    <Snackbar
      open={displayAlert}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        severity="error"
        variant="filled"
        sx={{ backgroundColor: "primary.main", color: "white" }}
        onClose={() => {
          setDisplayAlert(false)
        }}
      >
        {alert_text}
      </Alert>
    </Snackbar>
  )
}
