import { Helmet } from "react-helmet"

type Props = {
  title: string
  /**
   * Just uses the title, not adding "- Luna" at the end.
   */
  noSuffix?: boolean
}

export const MetaTags = ({ title, noSuffix }: Props) => {
  if (noSuffix) {
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    )
  }
  return (
    <Helmet>
      <title>{title} | Luna</title>
    </Helmet>
  )
}
