import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
  useTheme,
} from "@mui/material"
import { round } from "lodash"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { FormattedMessage, useIntl } from "react-intl"
import {
  getCurrentModelData,
  getCurrentModelLevels,
} from "services/offshore.service"
import HighchartsReact from "highcharts-react-official"
import OceanCurrentHighcharts from "./OceanCurrentHighcharts"
import { useIncrementWhenChanged } from "components/useIncrementWhenChanged"
import CurrentRose, { CurrentData } from "components/CurrentRose/CurrentRose"
import { Pause, PlayArrow } from "@mui/icons-material"
import { BorderedIconButton } from "components/BorderedIconButton"
import moment from "moment"
import { convertToKnots, useTimezone, useWindUnit } from "@luna/luna-core"
import {
  useDepth,
  useThresholdCurrent,
} from "components/Offshore/OffshoreGraph/OffshoreGraph.hook"
import { MetaTags } from "MetaTags"
import { SnackbarAlert } from "components/SnackbarAlert"
import { DateTime } from "luxon"

import ValidFrom from "components/ValidFrom"

type Props = { current?: Current }
export const OceanCurrent: React.FC<Props> = ({ current }) => {
  const [currentSliderValue, setCurrentSliderValue] = useState(0)
  const [error, setError] = React.useState<Error>()
  const [isPlaying, setIsPlaying] = useState(false)
  const { currentTimezone } = useTimezone()
  const intl = useIntl()
  const theme = useTheme()

  const chartRef = useRef<HighchartsReact.RefObject>()

  const [currentForecast, setCurrentForecast] = useState<
    OffshoreForecast | undefined
  >()

  const [currentDepthLevels, setCurrentDepthLevels] = useState<number[]>([])

  const { thresholdCurrentInMS, setThresholdCurrentInMS } =
    useThresholdCurrent()

  const defaultDepthLevel = 1

  const currentAgeLimit = 36

  const { depth, setDepth } = useDepth()

  const handleChangeThresholdCurrent = (event: SelectChangeEvent<string>) => {
    setThresholdCurrentInMS(event.target.value as string)
  }

  const handleChangeDepth = (event: SelectChangeEvent<string>) => {
    setDepth(event.target.value as string)
  }

  const { windUnit } = useWindUnit()

  const { increment } = useIncrementWhenChanged({ queryParam: "openDialog" })

  const observedData = false

  const title = `${intl.formatMessage({
    id: "current",
  })} - ${current?.title}`

  useEffect(() => {
    if (isPlaying) {
      const intervalID = setInterval(() => {
        setCurrentSliderValue((prev) => {
          if (currentForecast && prev === currentForecast.forecast.length - 1) {
            return 0
          }
          return prev + 1
        })
      }, 600)
      return () => clearInterval(intervalID)
    }
  }, [isPlaying, currentForecast])

  useEffect(() => {
    const loadCurrentModelLevels = async () =>
      current &&
      getCurrentModelLevels(current.model, current.interpol_pos_id)
        .then((data) => {
          setCurrentDepthLevels(data)
        })
        .catch((err: Error) => {
          setError(err)
          const message = "Failed to load CurrentEPSForecast Data."
          console.log(message, err)
        })
    loadCurrentModelLevels()
  }, [current])

  useEffect(() => {
    let realDepth: number | undefined

    if (currentDepthLevels.includes(Number(depth))) {
      realDepth = Number(depth)
    } else if (currentDepthLevels.length > 0) {
      realDepth = currentDepthLevels[0]
    }

    const loadCurrentModelData = async () =>
      current &&
      getCurrentModelData(current.model, current.interpol_pos_id, realDepth)
        .then((data) => {
          data.forecast = data.forecast.map((fc) => {
            if (
              fc.data["current_direction"] < 0 ||
              fc.data["current_direction"] > 360
            ) {
              delete fc.data["current_direction"]
            }
            if (fc.data["current_speed"] < 0) {
              delete fc.data["current_speed"]
            }
            return fc
          })
          return data
        })
        .then((data) => {
          setCurrentForecast(data)
        })
        .catch((err: Error) => {
          setError(err)
          const message = "Failed to load CurrentEPSForecast Data."
          console.log(message, err)
        })
    loadCurrentModelData()

    const intervalID = setInterval(loadCurrentModelData, 1000 * 60 * 10) // ten minute intervals.
    return () => {
      clearInterval(intervalID)
    }
  }, [current, depth, currentDepthLevels])

  if (!currentForecast) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }

  const run = DateTime.fromISO(moment(currentForecast.meta.run).toISOString())

  const now = DateTime.now()

  const diffHours = now.diff(run, "hours")

  return (
    <Grid container spacing={2}>
      <MetaTags title={title} />
      <Grid item xs={12}>
        <Typography variant="h2">
          <FormattedMessage id="ocean_current" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{current?.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ValidFrom valid={currentForecast?.meta.run} />
      </Grid>

      <Grid item sx={{ backgroundColor: theme.palette.common.white }} xs={12}>
        <Grid container spacing={2}>
          <Grid item sx={{ backgroundColor: theme.palette.common.white }}>
            <FormattedMessage id="set_threshold" />
          </Grid>

          <Grid item sx={{ backgroundColor: theme.palette.common.white }}>
            <FormControl sx={{ alignItems: "baseline" }}>
              <Select
                labelId="thresholdCurrent-label"
                id="thresholdCurrent"
                value={thresholdCurrentInMS}
                onChange={handleChangeThresholdCurrent}
                sx={{ height: "2rem" }}
              >
                {[0.1, 0.2, 0.3, 0.4, 0.5, 0.6].map((thresholdCurrentInMS) => {
                  const unitIsKnots = windUnit === "KT"
                  let threshold = thresholdCurrentInMS
                  if (unitIsKnots) {
                    threshold = round(
                      convertToKnots({
                        metersPerSecond: thresholdCurrentInMS,
                      }),
                      1
                    )
                  }

                  return (
                    <MenuItem
                      key={thresholdCurrentInMS}
                      value={thresholdCurrentInMS}
                    >
                      {threshold}{" "}
                      <FormattedMessage id={unitIsKnots ? "knots" : "m/s"} />
                    </MenuItem>
                  )
                })}

                <MenuItem key={999} value={999}>
                  <FormattedMessage id="none" />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ backgroundColor: theme.palette.common.white }}>
            <FormattedMessage id="depth" />
          </Grid>
          <Grid item sx={{ backgroundColor: theme.palette.common.white }}>
            <FormControl sx={{ alignItems: "baseline" }}>
              {currentDepthLevels && currentDepthLevels.length > 0 ? (
                <Select
                  labelId="currentdepth-label"
                  id="currentdepth"
                  value={depth}
                  onChange={handleChangeDepth}
                  sx={{ height: "2rem" }}
                >
                  {currentDepthLevels &&
                    currentDepthLevels.map((depth) => {
                      return (
                        <MenuItem key={depth} value={depth}>
                          {depth} m
                        </MenuItem>
                      )
                    })}
                </Select>
              ) : (
                <Select
                  labelId="currentdepth-label"
                  id="currentdepth"
                  value={defaultDepthLevel.toString()}
                  onChange={handleChangeDepth}
                  sx={{ height: "2rem" }}
                  disabled={true}
                >
                  <MenuItem key={defaultDepthLevel} value="1" selected>
                    {defaultDepthLevel} m
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: currentForecast?.meta?.epsMembers ? "block" : "none",
          flexDirection: "column",
          width: "100%",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            backgroundColor: theme.palette.common.white,
          }}
        >
          <CurrentRose
            data={
              currentForecast?.forecast?.[currentSliderValue]
                ?.data as CurrentData
            }
          />
        </Grid>
        <Box sx={{ display: "flex", gap: 4, mx: 4 }}>
          <Grid item sx={{ backgroundColor: theme.palette.common.white }}>
            <BorderedIconButton
              onClick={() => setIsPlaying((prev) => !prev)}
              aria-label={
                isPlaying
                  ? "stop avspilling av størmrose-data"
                  : "start avspilling av størmrose-data"
              }
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </BorderedIconButton>
          </Grid>
          <Grid
            item
            xs
            sx={{
              backgroundColor: theme.palette.common.white,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Slider
              value={currentSliderValue}
              onChange={(_, value) => setCurrentSliderValue(value as number)}
              min={0}
              max={currentForecast.forecast.length - 1}
              step={1}
              valueLabelFormat={(value) =>
                moment(currentForecast.forecast[value].valid)
                  .locale(intl.locale)
                  .tz(currentTimezone)
                  .format("DD MMM YYYY HH:mm")
              }
              valueLabelDisplay="auto"
              sx={{
                my: 2,
                "& .MuiSlider-mark": {
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                },
                "& .MuiSlider-rail": {
                  height: "8px",
                },

                "& .MuiSlider-mark[data-index='0'], & .MuiSlider-mark[data-index='4'], & .MuiSlider-mark[data-index='8'], & .MuiSlider-mark[data-index='12'], & .MuiSlider-mark[data-index='16']":
                  {
                    width: "10px",
                    height: "10px",
                  },
              }}
              marks={
                currentForecast.forecast
                  .map((e, index) => {
                    if (index === 0)
                      return {
                        value: index,
                        label: moment(e.valid)
                          .locale(intl.locale)
                          .tz(currentTimezone)
                          .format("zz DD MMM HH:mm"),
                      }
                    if (index === currentForecast.forecast.length - 1)
                      return {
                        value: index,
                        label: moment(e.valid)
                          .locale(intl.locale)
                          .tz(currentTimezone)
                          .format("DD MMM"),
                      }
                    if (index % 24 === 0) {
                      return {
                        value: index,
                        label: moment(e.valid)
                          .locale(intl.locale)
                          .tz(currentTimezone)
                          .format("DD MMM HH:mm"),
                      }
                    }

                    if (index % 6 === 0) {
                      return {
                        value: index,
                      }
                    }
                    return null
                  })
                  .filter(Boolean) as { value: number; label: string }[]
              }
              getAriaValueText={(value) => `Strømdata nummer ${value}`}
              aria-labelledby="discrete-slider-always"
            />
          </Grid>
        </Box>
      </Box>
      {observedData && (
        <Grid item xs={12} sx={{ backgroundColor: theme.palette.common.white }}>
          <Grid sx={{ ml: 9 }}>
            <Button
              aria-controls="download-menu"
              aria-haspopup="true"
              variant="outlined"
              color="primary"
              startIcon={<VisibilityIcon />}
            >
              <FormattedMessage id={"show_observation"} />
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} sx={{ backgroundColor: theme.palette.common.white }}>
        {error && (
          <SnackbarAlert
            alert_text={intl.formatMessage({ id: "missing_current_modeldata" })}
          />
        )}
        {diffHours.hours > currentAgeLimit && (
          <SnackbarAlert
            alert_text={intl.formatMessage({ id: "current_modeldata_late" })}
          />
        )}
        <OceanCurrentHighcharts
          key={increment}
          ref={chartRef}
          apiData={currentForecast}
          thresholdCurrentInMS={Number(thresholdCurrentInMS)}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",

            gap: 1,
          }}
        ></Box>
      </Grid>
      {currentForecast.meta.epsMembers && (
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: theme.palette.common.white }}
        >
          <Grid item>
            <Typography>
              <FormattedMessage id="current_ensemble_text" />{" "}
              {currentForecast.meta.epsMembers}{" "}
              <FormattedMessage id="members" />
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
