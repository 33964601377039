import { Box, Container } from "@mui/material"
import { MarkdownLoader } from "components/MarkdownLoader"
import { MetaTags } from "MetaTags"
import { useIntl } from "react-intl"

interface Props {
  location?: string
}
export const OffshoreMenuExplanation: React.FC<Props> = ({ location }) => {
  const { locale } = useIntl()
  const intl = useIntl()

  const location_title = location ? ` - ${location}` : ""
  const title = `${intl.formatMessage({
    id: "explanation",
  })} ${location_title}`

  return (
    <Container maxWidth={"xl"}>
      <MetaTags title={title} />
      <Box paddingLeft={2} paddingBottom={3}>
        <MarkdownLoader url={`/markdown/offshore-explanation-${locale}.md`} />
      </Box>
    </Container>
  )
}
