import { Box, Container, Grid, Paper, Typography, Stack } from "@mui/material"
import { BreakOutPortal } from "components/BreakOutPortal"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { Link } from "components/Link"
import { Li } from "components/Lists/Li"
import { Ul } from "components/Lists/Ul"
import SymbolExplanation from "components/SymbolExplanation"
import { TextForecast } from "components/TextForecast/TextForecast"
import { useUserProducts } from "components/UserProductsProvider"
import { MapRenderContainer } from "components/WeatherMaps/MapRenderer/MapRenderContainer"
import { XMLForecastLoader } from "components/XMLForecastLoader"
import Meteogram from "../components/Meteogram/Meteogram"
import { FormattedMessage, useIntl } from "react-intl"
import { TextForecastOverview } from "../components/TextForecast/TextForecastOverview"
import { OceanCurrentOverview } from "components/OceanCurrent/OceanCurrentOverview"

import { MetaTags } from "MetaTags"

export const FrontPageEnergy = () => {
  const userProducts = useUserProducts()
  const intl = useIntl()
  const [textForecast] = userProducts.text_forecasts
  const { text_forecasts = [], currents = [] } = userProducts

  const hasOceanCurrent =
    userProducts.hasProduct("current") && currents.length > 0

  const title = `${intl.formatMessage({
    id: "weatherforecast",
  })} - ${userProducts.customer}`

  return (
    <>
      <Stack spacing={4}>
        <MetaTags title={title} />
        <Typography variant="h1">{title}</Typography>
        <div>
          {text_forecasts.length > 1 && (
            <Grid
              item
              sx={{
                display: "flex",
                pb: 4,
              }}
            >
              <TextForecastOverview textforecast={text_forecasts} />
            </Grid>
          )}
          {text_forecasts.length === 1 && (
            <Grid container spacing={4}>
              <Grid item md={4}>
                <Paper variant="elevation" sx={{ height: "100%" }}>
                  <Box padding={4} sx={{ minHeight: "400px" }}>
                    {textForecast.filename !== "filnavn" ? (
                      <TextForecast fileName={textForecast.filename} />
                    ) : (
                      <TextForecast />
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item md={8}>
                <Paper variant="elevation">
                  <Box padding={4}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <Typography variant="h3" component="h2">
                            <FormattedMessage id="weather_maps" />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={4}
                          wrap="nowrap"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <SymbolExplanation />
                          </Grid>
                          <Grid item>
                            <FullscreenDialogButton
                              type="minimal"
                              dialogID="analysekart"
                              style={{ float: "right" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FullscreenDialog
                          id="analysekart"
                          title={intl.formatMessage({ id: "weather_maps" })}
                          showChildrenAlsoWhenClosed
                        >
                          <MapRenderContainer>
                            <XMLForecastLoader type="analyse-kart">
                              {({ latest, formattedDate }) => (
                                <div>
                                  <Typography
                                    variant="h3"
                                    component="h2"
                                    gutterBottom
                                  >
                                    <FormattedMessage id="analysis" />
                                  </Typography>
                                  <Typography variant="subtitle1" gutterBottom>
                                    {formattedDate}
                                  </Typography>
                                  <img src={latest.uri} alt="analyse kart" />
                                </div>
                              )}
                            </XMLForecastLoader>
                            <XMLForecastLoader type="prognose-kart">
                              {({ latest, formattedDate }) => (
                                <div>
                                  <Typography
                                    variant="h3"
                                    component="h2"
                                    gutterBottom
                                  >
                                    <FormattedMessage id="prognosis" />
                                  </Typography>
                                  <Typography variant="subtitle1" gutterBottom>
                                    {formattedDate}
                                  </Typography>
                                  <img src={latest.uri} alt="prognose kart" />
                                </div>
                              )}
                            </XMLForecastLoader>
                          </MapRenderContainer>
                        </FullscreenDialog>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
        </div>
        {userProducts?.products.includes("meteogram") && (
          <Box
            sx={{
              // Support sideways scroll on mobile
              overflowX: {
                xs: "scroll",
                sm: "scroll",
                md: "scroll",
                lg: "initial",
              },
              marginLeft: (theme) => `-${theme.spacing(4)} !important`,
              marginRight: (theme) => `-${theme.spacing(4)} !important`,
              paddingLeft: (theme) => theme.spacing(4),
              paddingRight: (theme) => theme.spacing(4),
            }}
          >
            <Paper
              variant="elevation"
              sx={{
                minWidth: "1232px",
                // Add a little margin to avoid cutting the Paper component's drop-shadow.
                marginBottom: (theme) => theme.spacing(3),
              }}
            >
              <Box padding={4} marginTop={4}>
                <Meteogram positions={userProducts.meteograms} />
              </Box>
            </Paper>
          </Box>
        )}
        {hasOceanCurrent && (
          <Grid
            item
            sx={{
              display: "flex",
              pb: 4,
            }}
          >
            <OceanCurrentOverview oceancurrentList={currents} />
          </Grid>
        )}
      </Stack>

      <BreakOutPortal where="right-above-footer">
        <Box
          marginTop={6}
          style={{
            background: "#fff url(/tiling-background.svg)",
          }}
        >
          <Container>
            <Box paddingY={6}>
              <Grid container direction="row" spacing={3} alignItems="stretch">
                <Grid item xs={12} sm={4}>
                  <Paper variant="elevation" style={{ height: "100%" }}>
                    <Box padding={4}>
                      <Typography variant="h3" component="h2" gutterBottom>
                        <FormattedMessage id="missing_something_in_luna_headline" />
                        ?
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <FormattedMessage id="missing_something_in_luna_body" />
                        .
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <FormattedMessage id="email" />: luna@met.no
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper variant="elevation" style={{ height: "100%" }}>
                    <Box padding={4}>
                      <Typography variant="h3" component="h2" gutterBottom>
                        <FormattedMessage id="other_services_from_met" />
                      </Typography>
                      <Typography variant="body1" component="div">
                        <Ul>
                          <Li>
                            <Link to="https://www.yr.no/nb/farevarsler">
                              <FormattedMessage id="warning_forecast_from_yr" />
                            </Link>
                          </Li>
                          <Li>
                            <Link
                              to={intl.formatMessage({ id: "lightning_url" })}
                            >
                              <FormattedMessage id="lightning" />
                            </Link>
                          </Li>
                          <Li>
                            <Link to="https://www.met.no/vaer-og-klima/tekstvarsel">
                              <FormattedMessage id="text_forecast_from_met" />
                            </Link>
                          </Li>
                          <Li>
                            <Link to="https://www.met.no/vaer-og-klima/satellittbilder">
                              <FormattedMessage id="satellite_images_from_met" />
                            </Link>
                          </Li>
                        </Ul>
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Paper variant="elevation" style={{ height: "100%" }}>
                    <Box padding={4}>
                      <Typography variant="h3" component="h2" gutterBottom>
                        Luna
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <FormattedMessage id="what_is_luna" />
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <Link to="https://www.met.no/om-oss/oppdragsverksemd">
                          <FormattedMessage id="commercial_service" />
                        </Link>
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </BreakOutPortal>
    </>
  )
}
