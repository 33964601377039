import { Container, Grid } from "@mui/material"
import OffshoreGraph from "../OffshoreGraph/OffshoreGraph"
import SignWeather from "../SignWeather"

import { useIntl } from "react-intl"
import { MetaTags } from "MetaTags"

interface Props {
  report: OffshoreReportData
}

export const OffshoreMenuGraph: React.FC<Props> = ({ report }) => {
  const intl = useIntl()

  const title = `${intl.formatMessage({
    id: "graph",
  })} - ${report.offshoreData.position_name}`

  return (
    <Container maxWidth={"xl"}>
      <MetaTags title={title} />
      <Grid container spacing={2}>
        <SignWeather offshoreData={report.offshoreData} />
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <OffshoreGraph report={report} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
