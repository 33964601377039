import { GetApp, Home, Map, TrackChanges } from "@mui/icons-material"
import {
  Box,
  Container,
  Paper,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material"
import { isMapProduct } from "components/UserProductIDs.service"
import { useUserProducts } from "components/UserProductsProvider"
import { DownloadPage } from "pages/DownloadPage"
import { FrontPageEnergy } from "pages/FrontPageEnergy"
import { WeatherRadarPage } from "pages/WeatherRadarPage"
import { Link, Route, Switch, useLocation } from "react-router-dom"
import { WeatherMapsRoute } from "./components/WeatherMaps/WeatherMapsRoute"
import { MetaTags } from "./MetaTags"

import { MessageAlerts, useMessages } from "components/Message/MessageAlerts"
import { useIntl } from "react-intl"
import { TextForecastPage } from "./pages/TextForecastPage"
import { OceanCurrentPage } from "pages/OceanCurrentPage"

/**
 * This component expects an <BrowserRouter/> component
 * further up in the component tree, see App.tsx.
 */
export const AppRoutesEnergyCustomer = () => {
  const location = useLocation()
  const userProducts = useUserProducts()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const hasRadar = userProducts.hasProduct("radar")
  const hasWeatherMap = !!userProducts?.products.find(isMapProduct)
  const hasDownload = userProducts.hasProduct("data_download")
  const hasTextForecast = userProducts.hasProduct("text_forecast")
  const hasCurrent = userProducts.hasProduct("current")
  const { messages } = useMessages({ autoRefresh: true })
  const intl = useIntl()

  return (
    <Box>
      <Paper>
        <Container>
          <Tabs
            value={getSelectedTab(location)}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            aria-label="tab-navigasjon for undersider"
          >
            <Tab
              icon={<Home />}
              label={intl.formatMessage({ id: "frontpage" })}
              component={Link}
              value="/"
              to="/"
            />
            {hasWeatherMap && (
              <Tab
                icon={<Map />}
                label={intl.formatMessage({ id: "map" })}
                component={Link}
                value="/maps"
                to="/maps"
              />
            )}
            {hasRadar && (
              <Tab
                icon={<TrackChanges />}
                label={intl.formatMessage({ id: "weatherradar" })}
                component={Link}
                value="/radar"
                to="/radar"
              />
            )}
            {hasDownload && (
              <Tab
                icon={<GetApp />}
                label={intl.formatMessage({ id: "downloaddata" })}
                component={Link}
                value="/download"
                to="/download"
              />
            )}
          </Tabs>
        </Container>
      </Paper>
      <Container>
        <MessageAlerts messages={messages} />
        <Box marginY={isDesktop ? 6 : 1}>
          <Switch>
            {hasWeatherMap && (
              <Route
                path="/maps"
                render={() => (
                  <>
                    <MetaTags title={intl.formatMessage({ id: "map" })} />
                    <WeatherMapsRoute />
                  </>
                )}
              />
            )}
            {hasRadar && (
              <Route
                path="/radar"
                render={() => (
                  <>
                    <MetaTags
                      title={intl.formatMessage({ id: "weatherradar" })}
                    />
                    <WeatherRadarPage />
                  </>
                )}
              />
            )}
            {hasDownload && (
              <Route
                path="/download"
                render={() => (
                  <>
                    <MetaTags
                      title={intl.formatMessage({ id: "downloaddata" })}
                    />
                    <DownloadPage />
                  </>
                )}
              />
            )}
            {hasTextForecast && (
              <Route
                path="/textforecast/:filename?/:noSynopticMap?"
                render={() => (
                  <>
                    <MetaTags
                      title={intl.formatMessage({ id: "text_forecast" })}
                    />
                    <TextForecastPage showMsg={false} />
                  </>
                )}
              />
            )}
            {hasCurrent && (
              <Route
                path="/oceancurrent/:posID"
                render={() => (
                  <>
                    <MetaTags
                      title={intl.formatMessage({ id: "weatherradar" })}
                    />
                    <OceanCurrentPage />
                  </>
                )}
              />
            )}
            <Route path="/" render={() => <FrontPageEnergy />} />
          </Switch>
        </Box>
      </Container>
    </Box>
  )
}

// Had difficulty importing the correct type. Chose to
// infer the type from the function instead.
type Location = ReturnType<typeof useLocation>
/**
 * Ensure that even though we select some sub-view of a
 * given path we highlight the proper tab. <Tabs /> will
 * complain if we give it a value that it doesn't recognize
 * in it's <Tab value="" /> children.
 */
const getSelectedTab = (location: Location) => {
  if (location.pathname.startsWith("/maps")) {
    return "/maps"
  }
  return location.pathname
}
