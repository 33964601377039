import moment from "moment"
import { FormattedMessage } from "react-intl"

const ComingPastMsg: React.FC<{ from: string; to: string }> = ({
  from,
  to,
}) => {
  const fromTime = moment(from)
  const toTime = moment(to)
  const now = moment()

  if (now.isBefore(fromTime)) return <FormattedMessage id="coming" />
  if (now.isAfter(toTime)) return <FormattedMessage id="past" />
  return null
}

export default ComingPastMsg
