import { Container, Grid } from "@mui/material"
import { OceanCurrent } from "components/OceanCurrent/OceanCurrent"

interface Props {
  current?: Current
}

export const OffshoreMenuCurrent: React.FC<Props> = ({ current }) => {
  if (!current) {
    return null
  }

  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={2}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <OceanCurrent current={current} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
