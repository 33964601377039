import moment from "moment"
import { config } from "../config"
import { DateTime } from "luxon"
import { MessageAlert } from "components/Message/MessageType"

export type MapsStructure = [
  { product: string; maps: [{ url: string; mtime: string }] }
]

export async function getAvailableRadars(
  queryParams: RadarOptions
): Promise<Array<RadarResponse>> {
  //  since we're using the /available endpoint we dont need the time-query-param
  // until later, thus we're simply removing it.

  const queryCopy: any = queryParams
  delete queryCopy.time
  const queries = new URLSearchParams(
    queryCopy as unknown as Record<string, string>
  )

  return fetch(
    `${config.api.weatherUrl}/radar/2.0/available.json?${queries.toString()}`
  )
    .then(checkStatus)
    .then((res) => res.json())
    .then((res) =>
      res.map((elem: RadarResponse) => {
        return {
          ...elem,
          params: {
            ...elem.params,
            time: DateTime.fromISO(elem.params.time, { zone: "utc" }),
          },
        }
      })
    )
}

export async function getSpecificMaps(
  mapList: string[]
): Promise<MapsStructure> {
  const mapQueries = mapList.reduce((acc, map, index) => {
    if (index === 0) {
      acc = `map=${map}`
    } else {
      acc = acc + `&map=${map}`
    }
    return acc
  }, "")
  return fetch(
    `${config.api.lunaUrl}/api/v1/products/weathermap/available?${mapQueries}`,
    {
      headers: {
        authorization: await authorizationHeader(),
      },
    }
  )
    .then(checkStatus)
    .then((res) => res.json())
}

// define different signatures to get intellisense on
// return-values based on usage
export async function getAvailableMaps(): Promise<string[]>
export async function getAvailableMaps(map: string): Promise<MapsStructure>
export async function getAvailableMaps(map: string[]): Promise<MapsStructure>
export async function getAvailableMaps(
  map: string[] | string
): Promise<MapsStructure>
export async function getAvailableMaps(
  map?: string | string[] | undefined
): Promise<MapsStructure | string[]> {
  let mapList = []
  if (!Array.isArray(map)) {
    mapList = [map]
  } else {
    mapList = map
  }

  const mapQueries = mapList.reduce((acc, map, index) => {
    if (index === 0) {
      acc = `?map=${map}`
    } else {
      acc = acc + `&map=${map}`
    }
    return acc
  }, "")

  return fetch(
    `${config.api.lunaUrl}/api/v1/products/weathermap/available${mapQueries}`,
    {
      headers: {
        authorization: await authorizationHeader(),
      },
    }
  )
    .then(checkStatus)
    .then((res) => res.json())
}

export const getAvailabelArchivedOffshoreForecast = async ({
  orderId,
}: {
  orderId: number
}): Promise<AvailableArchivedForecast[]> =>
  fetch(
    `${config.api.lunaUrl}/api/v1/products/offshore/archive/${orderId}/available`,
    {
      headers: {
        authorization: await authorizationHeader(),
      },
    }
  )
    .then(checkStatus)
    .then((res) => res.json())

export async function getUserProducts(): Promise<UserProducts> {
  return fetch(`${config.api.lunaUrl}/api/v1/admin/userproducts`, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then(checkStatus)
    .then((res) => res.json())
    .then((result: UserProducts) => ({
      ...result,
      radars: processRadars(result.radars),
    }))
}

function processRadars(unprocessed: any): Radar[] {
  return unprocessed.map((radar: any) => ({
    ...radar,
    time: DateTime.fromISO(radar.time),
    sequence: radar?.sequence.split(";"),
  }))
}

export async function getLocationForecastData(
  lat: number,
  lon: number,
  altitude: number
): Promise<LocationForecastData> {
  return await fetch(
    `${config.api.locationforecastUrl}?lat=${lat}&lon=${lon}&altitude=${altitude}`
  )
    .then(checkStatus)
    .then((response) => response.json())
    .then(parseLocationForecastData)
}

export function parseLocationForecastData(
  data: RawDateApiResponse<LocationForecastData>
): LocationForecastData {
  const {
    properties: {
      meta: { updated_at, ...restOfMeta },
      timeseries,
    },
    ...rest
  } = data
  // Convert data and ensure all dates are no longer strings.
  const parsed: LocationForecastData = {
    ...rest,
    properties: {
      ...data.properties,
      meta: {
        ...restOfMeta,
        updated_at: moment.utc(updated_at),
      },
      timeseries: timeseries.map(({ time, data }) => ({
        time: moment.utc(time),
        data,
      })),
    },
  }
  return parsed
}

export const checkStatus = (res: Response) => {
  if (res.status >= 200 && res.status < 300) {
    return res
  } else {
    var error = new Error("status code " + res.status)
    throw error
  }
}

export const authorizationHeader = async (): Promise<string> => {
  if (process.env.NODE_ENV === "test") {
    return "" // do nothing when testing
  }
  const keycloak = window.configuredKeycloak
  try {
    // Refreshes if soon to be expired
    const refreshed = await keycloak.updateToken(60)
    if (refreshed) {
      console.log("Token was successfully refreshed")
    }
  } catch (err) {
    console.log("Failed to refresh the token, or the session has expired")
  }
  return "Bearer " + keycloak.token
}

async function downloadFile({
  url,
  filename,
}: {
  url: string
  filename: string
}) {
  return fetch(url, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then(checkStatus)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = filename
      // we need to append the element to the dom -> otherwise it will not work in firefox
      document.body.appendChild(a)
      a.click()
      //afterwards we remove the element again
      a.remove()
    })
    .catch((err) => {
      console.log(`Failed to fetch url '${url}', error was`, err)
      // re-throwing to be caught by Sentry
      throw err
    })
}

/**
 * Add support for downloading files that need
 * authorization header. JS downloads the file
 * to the browser, and then we attach the file
 * to an invisible element before clicking on it.
 *
 * See: https://stackoverflow.com/a/42274086
 *
 * @param api decides whether we download from luna-files or luna-backend, defaults to backend on undefined
 */
export const useFileDownloader = (api?: "luna" | "luna-files") => {
  const apiUrl = api === "luna-files" ? config.api.filesUrl : config.api.lunaUrl
  return {
    downloadFile: async ({
      url,
      filename,
    }: {
      url: string
      filename: string
    }) => {
      if (url.startsWith("http")) {
        return downloadFile({ url, filename })
      }
      return downloadFile({ url: `${apiUrl}${url}`, filename })
    },
  }
}

/**
 * loadLunaFile let's us fetch protected files
 * such as images and display them using data URIs.
 * @param keycloak keycloak instance
 * @param path path on server
 * @returns data encoded as data URI
 */
export async function loadLunaFile(path: string): Promise<string> {
  return fetch(`${config.api.lunaUrl}${path}`, {
    headers: {
      authorization: await authorizationHeader(),
    },
    cache: "no-cache",
  })
    .then((res) => res.blob())
    .then((res) => URL.createObjectURL(res))
    .catch((err) => {
      console.error(err)
      return ""
    })
}

/**
 * loadWeatherApiFile let's us fetch files from the weatherApi
 * such as images and display them using data URIs.
 * @param path path on server
 * @returns data encoded as data URI
 */
export async function loadWeatherAPIFile(path: string): Promise<string> {
  return fetch(`${config.api.weatherUrl}${path}`, {
    cache: "no-cache",
  })
    .then(async (res) => ({ blob: await res.blob(), headers: res.headers }))
    .then((res) => URL.createObjectURL(res.blob))
    .catch((err) => {
      console.error(err)
      return ""
    })
}

export async function fetchMessageAlertsJSON(): Promise<MessageAlert[]> {
  return fetch(`${config.api.lunaUrl}/api/v1/msg/user`, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then(checkStatus)
    .then((res) => res.json())
}
