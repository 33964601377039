import { EXWWOutlookPage, EXWWPage } from "@luna/luna-xww"
import { Box, Container } from "@mui/material"
import { OffshoreProductID } from "components/UserProductIDs.service"
import { useUserProducts } from "components/UserProductsProvider"
import { MetaTags } from "MetaTags"

import { Route, Switch } from "react-router-dom"

import FrontPageOffshore from "./pages/FrontPageOffshore"
import NoMatch404Page from "./pages/NoMatch404Page"
import OffshorePage from "./pages/OffshorePage"
import { TextForecastPage } from "./pages/TextForecastPage"
import { OceanCurrentPage } from "pages/OceanCurrentPage"
import { useIntl } from "react-intl"

type ProductRoute = {
  url: string
  title: string
  Component: React.FC
}

const productRoutes: Record<OffshoreProductID, ProductRoute> = {
  exww: {
    url: "/exww/:exwwSubPage/:platform?",
    title: "exww_title",
    Component: EXWWPage,
  },
  exwwoutlook: {
    url: "/exwwoutlook/",
    title: "seven_day_exww",
    Component: EXWWOutlookPage,
  },
  text_forecast: {
    url: "/textforecast/:filename?/:noSynopticMap?",
    title: "text_forecast",
    Component: TextForecastPage,
  },
  ocean_current: {
    url: "/oceancurrent/:posID",
    title: "ocean_current",
    Component: OceanCurrentPage,
  },
}

export function AppRoutesOffshore() {
  const userProducts = useUserProducts()
  const { hasProduct } = userProducts
  const intl = useIntl()

  return (
    <Box>
      <Switch>
        {Object.entries(productRoutes).map(([productID, routeData]) => {
          if (!hasProduct(productID)) {
            return null
          }
          const { url, title, Component } = routeData

          const page_title = `${intl.formatMessage({ id: title })}`
          return (
            <Route
              key={productID}
              exact
              path={url}
              render={() => (
                <>
                  <MetaTags title={page_title} />
                  <Component />
                </>
              )}
            />
          )
        })}
        <Route
          exact
          path="/offshore/:offshoreSubPage/:orderId"
          children={<OffshorePage />}
        />
        {/* remove this when OceanCurrent is in useUserProducts */}
        <Route
          exact
          path="/oceancurrent/:posID"
          children={<OceanCurrentPage />}
        />
        <Container>
          <Route exact path="/" children={<FrontPageOffshore />} />
        </Container>
        <Route path="/" component={NoMatch404Page} />
      </Switch>
    </Box>
  )
}
