import React from "react"
import moment, { Moment } from "moment"
import { useTimezone } from "@luna/luna-core"
import { FormattedMessage, useIntl } from "react-intl"

interface Props {
  date: Moment | string
}

const LastPublishedWhen: React.FC<Props> = ({ date }) => {
  const { currentTimezone } = useTimezone()
  const intl = useIntl()
  const parsedDate = typeof date === "string" ? moment(date) : date
  if (!date) {
    return (
      <>
        <FormattedMessage id="published" />
      </>
    )
  }
  return (
    <>
      <FormattedMessage id="published" />
      {": "}
      {parsedDate
        .locale(intl.locale)
        .tz(currentTimezone)
        .format("DD.MM.YYYY HH:mm zz")}
    </>
  )
}

export default LastPublishedWhen
