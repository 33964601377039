import { Box, Container, Typography } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import OffshoreMaps from "../OffshoreMaps/OffshoreMaps"

import { MetaTags } from "MetaTags"

interface Props {
  location: string
}

export const OffshoreMenuMaps: React.FC<Props> = ({ location }) => {
  const intl = useIntl()

  const title = `${intl.formatMessage({
    id: "weather_maps",
  })} - ${location}`

  return (
    <Container maxWidth={"xl"}>
      <MetaTags title={title} />
      <Box paddingLeft={2} paddingBottom={3}>
        <Typography variant={"h1"}>
          <FormattedMessage id="weather_maps" />
        </Typography>
      </Box>

      <Box paddingLeft={2} paddingRight={2} paddingBottom={5}>
        <OffshoreMaps />
      </Box>
    </Container>
  )
}
