import FromToDateString from "./FromToDateString"
import { Grid, Typography, Theme } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { FormattedMessage } from "react-intl"
import ComingPastMsg from "./ComingPastMsg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContent: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    boxContentCompact: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "0.25px",
    },
    boxTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
      letterSpacing: "0.5px",
    },
    boxTitleCompact: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "17px",
      letterSpacing: "0.25px",
    },
    emc_box: {
      borderRadius: "10px",
      background: "#F8F7FD",
      marginBottom: theme.spacing(2),

      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
)

interface WindBoxProps {
  max_wind?: number
  max_wind_direction?: number
  max_wind_date_from?: string
  max_wind_date_to?: string
  compact?: boolean
}

const WindBox: React.FC<WindBoxProps> = ({
  max_wind,
  max_wind_direction,
  max_wind_date_from,
  max_wind_date_to,
  compact,
}) => {
  const classes = useStyles()

  return (
    <Grid container item xs={12} md={5} lg={12} className={classes.emc_box}>
      <Grid item xs={6} lg={6}>
        <Typography
          className={compact ? classes.boxTitleCompact : classes.boxTitle}
        >
          <FormattedMessage id="wind" />{" "}
          {max_wind_date_from && max_wind_date_to && (
            <ComingPastMsg from={max_wind_date_from} to={max_wind_date_to} />
          )}
        </Typography>
        <Typography
          className={compact ? classes.boxContentCompact : classes.boxContent}
        >
          [10m,10min]
        </Typography>
      </Grid>
      <Grid item xs={6} lg={6}>
        <Typography
          className={compact ? classes.boxTitleCompact : classes.boxTitle}
          style={{
            textAlign: "right",
          }}
        >
          {max_wind_direction}° / {max_wind} <FormattedMessage id="wind_unit" />
        </Typography>
        {max_wind_date_from && max_wind_date_to && (
          <Typography
            className={compact ? classes.boxContentCompact : classes.boxContent}
            style={{
              textAlign: "right",
            }}
          >
            <FromToDateString from={max_wind_date_from} to={max_wind_date_to} />
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default WindBox
