import { Box, Button, Grid, Typography } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Theme } from "@mui/material/styles"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HighchartsReact from "highcharts-react-official"
import moment from "moment"
import React, { useEffect, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { DownLoadFileDropDown } from "../../DownLoadFileDropDown"
import { FullscreenDialog } from "../../FullscreenDialog"
import { FullscreenDialogButton } from "../../FullscreenDialogButton"
import LoadingSpinner from "../../LoadingSpinner"
import { LongTimeForecastDropDown } from "../../LongTimeForecastDropDown"
import ValidFrom from "../../ValidFrom"
import { convertToMetersPerSecond, useWindUnit } from "@luna/luna-core"
import OffshoreGraphHighcharts from "./OffshoreGraphHighcharts"
import { formatObservedData } from "./OffshoreGraphOptions/graphOptionsUtils"
import { useThresholdWave, useThresholdWind } from "./OffshoreGraph.hook"
import { getObsData } from "services/observation.service"
import { OffshoreDataList } from "../../../@types/OffshoreGrapOption"
import { SnackbarAlert } from "components/SnackbarAlert"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      alignItems: "baseline",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

/**
 * This hook loads report data and re-loads that report data on a given
 * interval.
 */
export const useObservedData = (obsId: number | undefined) => {
  const [observedData, setObservedData] = React.useState<OffshoreDataList[]>()
  const [error, setError] = React.useState<Error>()

  useEffect(() => {
    const loadObsData = (obsId: number) =>
      getObsData({ obsId })
        .then((data) => {
          setObservedData(formatObservedData(data))
        })
        .catch((err: Error) => {
          setError(err)
        })
    if (obsId) {
      loadObsData(obsId)
      const intervalID = setInterval(() => loadObsData(obsId), 1000 * 60 * 5) // five-minute intervals
      return () => {
        clearInterval(intervalID)
      }
    }
  }, [obsId])
  return { obsData: observedData, error: error }
}

interface Props {
  report: OffshoreReportData
}

const OffshoreGraph: React.FC<Props> = ({ report }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const chartRef = useRef<HighchartsReact.RefObject>()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  // const [displayAlert, setDisplayAlert] = React.useState(true)
  const intl = useIntl()
  const observedDataFrost = useObservedData(
    report.offshoreData.frost_position_id
  )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()

  const handleChangeThresholdWave = (event: SelectChangeEvent<string>) => {
    setThresholdWave(event.target.value as string)
  }

  const { thresholdWave, setThresholdWave } = useThresholdWave()

  const { thresholdWindInKnots, setThresholdWindInKnots } = useThresholdWind()

  const handleChangeThresholdWind = (event: SelectChangeEvent<string>) => {
    setThresholdWindInKnots(event.target.value as string)
  }

  const { windUnit } = useWindUnit()

  if (!report) {
    return <LoadingSpinner />
  }

  const stationName = report.offshoreData.position_name

  const exportOptions = {
    filename: `offshore-graph-${moment(report.offshoreData.published).format(
      "DD-MM-YYYY"
    )}`,
    sourceWidth: 2500,
    sourceHeight: 900,
  }

  let menuButtonIcon = <ExpandMoreIcon />

  const isFullScreen =
    window.location.search.indexOf("openDialog=fullscreen-graph") !== -1

  return (
    <Box p={3} sx={{ backgroundColor: "background.paper" }}>
      <FullscreenDialog
        id="fullscreen-graph"
        title="Luna Offshore"
        showChildrenAlsoWhenClosed
      >
        <Box>
          <Typography component="h2" variant="h4">
            {stationName}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            <ValidFrom valid={report.qubaData.forecast[0].valid} />
          </Typography>
        </Box>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            {!isFullScreen && (
              <FullscreenDialogButton dialogID={"fullscreen-graph"} />
            )}
          </Grid>

          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <FormattedMessage id="set_threshold" />
              </Grid>
              <Grid item>
                <FormHelperText>
                  <FormattedMessage id="wave" />
                </FormHelperText>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="thresholdWave-label"
                    id="thresholdWave"
                    value={thresholdWave}
                    onChange={handleChangeThresholdWave}
                    sx={{ height: "2rem" }}
                  >
                    {[
                      0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5,
                      6.0,
                    ].map((threshold) => {
                      return (
                        <MenuItem key={threshold} value={threshold}>
                          {threshold} m
                        </MenuItem>
                      )
                    })}
                    <MenuItem value="">
                      <FormattedMessage id="none" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormHelperText>
                  <FormattedMessage id="wind" />
                </FormHelperText>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="thresholdWind-label"
                    id="thresholdWind"
                    value={thresholdWindInKnots}
                    onChange={handleChangeThresholdWind}
                    sx={{ height: "2rem" }}
                  >
                    {[10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(
                      (thresholdInKnots) => {
                        const windUnitIsMs = windUnit === "m/s"
                        let threshold = thresholdInKnots
                        if (windUnitIsMs) {
                          threshold = convertToMetersPerSecond(thresholdInKnots)
                        }
                        return (
                          <MenuItem
                            key={thresholdInKnots}
                            value={thresholdInKnots}
                          >
                            {threshold}{" "}
                            <FormattedMessage
                              id={windUnitIsMs ? "m/s" : "knots"}
                            />
                          </MenuItem>
                        )
                      }
                    )}
                    <MenuItem value="">
                      <FormattedMessage id="none" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <DownLoadFileDropDown id={report.id} report={report} />
              </Grid>
              <Grid item>
                <Button
                  aria-controls="download-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  endIcon={menuButtonIcon}
                >
                  <FormattedMessage id="downLoadPlot" />
                </Button>
                <Menu
                  id="download-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    key="1"
                    onClick={() => {
                      // Need to use any here because Typescript
                      // does not pick up on the export module
                      const chart: any = chartRef.current?.chart
                      chart.exportChartLocal(exportOptions)
                      handleClose()
                    }}
                  >
                    <FormattedMessage id="downLoadPNG" />
                  </MenuItem>
                  <MenuItem
                    key="2"
                    onClick={() => {
                      // Need to use any here because Typescript
                      // does not pick up on the export module
                      const chart: any = chartRef.current?.chart
                      chart.exportChartLocal({
                        ...exportOptions,
                        type: "image/svg+xml",
                      })
                    }}
                  >
                    <FormattedMessage id="downLoadSVG" />
                  </MenuItem>
                </Menu>
              </Grid>

              <Grid item>
                <LongTimeForecastDropDown id={report.offshoreData.order_id} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ backgroundColor: "background.paper" }}>
          {observedDataFrost.error && (
            <SnackbarAlert
              alert_text={intl.formatMessage({ id: "missing_obs" })}
            />
          )}
          <OffshoreGraphHighcharts
            ref={chartRef}
            qubaData={report.qubaData}
            observedData={
              observedDataFrost.error ? undefined : observedDataFrost.obsData
            }
            thresholdWave={Number(thresholdWave)}
            thresholdWindInKnots={Number(thresholdWindInKnots)}
          />
        </Box>
      </FullscreenDialog>
    </Box>
  )
}

export default OffshoreGraph
