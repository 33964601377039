import {
  Box,
  Breadcrumbs,
  Container,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { LunaLink } from "./LunaLink"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import CircularProgress from "@mui/material/CircularProgress"
import { Assignment, HelpOutline } from "@mui/icons-material"
import invert from "lodash/invert"
import React, { ChangeEvent, Fragment, useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import { getXWWReportData, XWWReportData } from "./dataFetcher"
import { MarkdownLoader } from "./MarkdownLoader"
import XWWPageContent from "./XWWPageContent"
import { MessageAlerts, useMessages } from "components/Message/MessageAlerts"
import { MetaTags } from "MetaTags"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      textAlign: "center",
      verticalAlign: "middle",
      margin: "20em",
    },
  })
)

interface QueryParams {
  platform?: string
}

interface Params {
  exwwSubPage: "summary" | "explanation"
  reportId: string
}

interface TabMap {
  [key: string]: number
}

const tabMap: TabMap = {
  summary: 0,
  explanation: 1,
}

const useTabsState = () => {
  const history = useHistory()
  const { exwwSubPage, reportId } = useParams<Params>()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const handleTabChange = (event: ChangeEvent<{}>, value: any) => {
    const newUrl = invert(tabMap)[value]
    history.push(`/exww/${newUrl}`)
  }
  useEffect(() => {
    const currentPageIndex = tabMap[exwwSubPage]
    setTabIndex(currentPageIndex)
  }, [exwwSubPage, reportId])
  return { tabIndex, handleTabChange }
}

const TabLabel: React.FC<{ icon: React.FunctionComponent<any> }> = ({
  icon: Icon,
  children,
}) => {
  return (
    <Box py={2}>
      <Icon style={{ verticalAlign: "middle", marginRight: "10px" }} />
      {children}
    </Box>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  )
}

const EXWWPage: React.FC = () => {
  const classes = useStyles()
  const { platform } = useParams<QueryParams>()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { locale } = useIntl()
  const { tabIndex, handleTabChange } = useTabsState()
  const { messages } = useMessages({ autoRefresh: true })
  const intl = useIntl()

  const history = useHistory()
  const onPlatformChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    history.push({ pathname: "/exww/summary/" + event.target.value })
  }
  const [report, setReport] = React.useState<XWWReportData | undefined>()
  useEffect(() => {
    getXWWReportData("ekofisk")
      .then((data) => setReport(data))
      .catch((err: Error) => {
        const message = "Failed to load EXWW report."
        console.log(message, err)
        // Re-throw error so it's caught by <ErrorBoundary />
        throw new Error(message)
      })
  }, [])
  if (!report) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    )
  }

  const title = `${intl.formatMessage({
    id: "explanation",
  })} - ${intl.formatMessage({
    id: "exww_title",
  })}`

  return (
    <Fragment>
      <Paper>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          {...(isDesktop
            ? { centered: true }
            : {
                variant: "scrollable",
              })}
        >
          <Tab
            label={
              <TabLabel icon={Assignment}>
                <FormattedMessage id="overview" />
              </TabLabel>
            }
            {...a11yProps(0)}
          />

          <Tab
            label={
              <TabLabel icon={HelpOutline}>
                <FormattedMessage id="explanation" />
              </TabLabel>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Paper>
      <MessageAlerts messages={messages} />
      <Container maxWidth={"xl"}>
        <Box pt={2} pb={2} pl={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <LunaLink to="/">Luna</LunaLink>
            <Typography color="textPrimary">
              <FormattedMessage id="exww_title" />
            </Typography>
          </Breadcrumbs>
        </Box>
      </Container>

      <TabPanel value={tabIndex} index={0}>
        <XWWPageContent
          onPlatformChange={onPlatformChange}
          platform={platform}
          report={report}
        />
      </TabPanel>

      <TabPanel index={tabIndex} value={1}>
        <Container maxWidth={"xl"}>
          <MetaTags title={title} />
          <Box paddingLeft={2} paddingBottom={3}>
            <MarkdownLoader url={`/markdown/exww-explanation-${locale}.md`} />
          </Box>
        </Container>
      </TabPanel>
    </Fragment>
  )
}

export default EXWWPage
