import { Paper, Box, Grid, Typography, Container } from "@mui/material"
import { Radar } from "components/Radar/Radar"
import { useUserProducts } from "components/UserProductsProvider"
import { MetaTags } from "MetaTags"
import { FormattedMessage, useIntl } from "react-intl"

interface Props {
  location?: string
}
export const OffshoreRadarPage: React.FC<Props> = ({ location }) => {
  const intl = useIntl()
  const userProducts = useUserProducts()

  const hasRadar = userProducts.hasProduct("radar")
  const { radars } = userProducts

  const url = window.location.pathname
  const orderId = url.split("/").pop()

  const location_title = location ? ` - ${location}` : ""
  const title = `${intl.formatMessage({
    id: "weatherradar",
  })} ${location_title}`

  return (
    <>
      <MetaTags title={title} />
      <Container>
        <Box sx={{ px: 3 }}>
          <Typography variant="h2" component="h1">
            <FormattedMessage id="weatherradar" />
          </Typography>
          <Grid
            direction="row"
            container
            spacing={2}
            justifyContent="space-between"
          >
            {hasRadar && radars.length > 0 ? (
              radars
                .filter(
                  (radar) => radar?.offshore_order?.toString() === orderId
                )
                .map((radar, i) => {
                  return (
                    <Grid item sm={12} md={6} key={radar.id}>
                      <Paper variant="elevation">
                        <Box padding={4} marginTop={4}>
                          <Typography variant="subtitle1" component="h3">
                            <Radar radar={radar} index={i} />
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  )
                })
            ) : (
              <Typography variant="body1">
                Du har ikke værradar konfigurert. Kontakt meteorologisk
                institutt om du ønsker dette.
              </Typography>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  )
}
